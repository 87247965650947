/* eslint-disable-next-line no-restricted-imports */
import styled, { keyframes } from 'styled-components';

import { SkeletonLoaderType } from 'Components/common/skeletonLoader/inteface';

import colors from 'Static/typography/colors';

const shimmer = keyframes`
	from {
		left: -50%;
	}
	to {
		left: 100%;
	}
`;

export const SkeletonLoaderWrapper = styled.div<SkeletonLoaderType>`
	background: ${colors.GREY.F8};
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	margin: ${({ margin }) => margin};
	border-radius: ${({ borderRadius }) => borderRadius};
	position: relative;
	overflow: hidden;

	.shimmer-wrapper {
		position: absolute;
		width: 6.3687rem;
		height: 34.0375rem;
		left: 34.58px;
		top: -21px;
		background: linear-gradient(
			90deg,
			rgba(226, 226, 226, 0) 0%,
			rgba(226, 226, 226, 0.6) 50.37%,
			rgba(226, 226, 226, 0) 100%
		);
		transform: rotate(12deg);
		animation: ${shimmer} 1.5s ease-in-out infinite;
	}
`;
