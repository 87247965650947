import React from 'react';
import { withRouter } from 'next/router';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import fonts from 'Static/typography/fonts';

import Conditional from './conditional';
import DangerousHTML from './dangerousHTML';

const HtmlSectionWrapper = styled.div`
	width: 90%;

	.content-html p {
		font-size: 1.0625rem;
		line-height: 1.8rem;
	}

	@media (max-width: 768px) {
		width: 100%;
		font-size: 0.625rem;

		h2 {
			margin-top: 0.625rem;
			font-family: ${fonts.HALYARD.DISPLAY};
			font-weight: 600;
			font-size: 1rem;
			color: #121212;
		}

		h3 {
			margin-top: 0.625rem;
			font-family: ${fonts.HALYARD.DISPLAY};
			font-weight: 600;
			font-size: 1rem;
			color: #121212;
		}

		ul {
			padding: 0 0 0 1.125rem;
			margin-top: 0.625rem;
			list-style: disc;

			li {
				line-height: 1.7;
				font-size: 0.9375rem;
			}
		}

		.sub-header {
			margin: 0;
		}

		a,
		p,
		a:not(.block):not(sup),
		p:not(.block):not(sup) {
			line-height: 1.7;
			font-size: 0.9375rem;
			margin-top: 0.9375rem;
			margin-bottom: 0;
		}

		strong {
			color: #121212;
			font-weight: 500;
		}

		img {
			width: 100%;
		}
	}
`;

type Props = {
	id?: string;
	heading?: string;
	htmlContent?: string;
	disableTranslate?: boolean;
	isCustomPage?: boolean;
};

const HtmlSectionFragment = (props: Props) => {
	const {
		id,
		disableTranslate,
		heading,
		htmlContent = '',
		isCustomPage,
	} = props;
	const className = disableTranslate ? 'notranslate' : 'translate';
	const whiteSpaceStrippedContent = htmlContent.replace(/[\s]+/g, ' ');
	return (
		<HtmlSectionWrapper className='html-section' id={id}>
			<Conditional if={heading}>
				<div className='sub-header'>
					<h2>{heading}</h2>
				</div>
			</Conditional>
			<DangerousHTML
				className={`${isCustomPage ? '' : 'content-html'} ${className}`}
				html={whiteSpaceStrippedContent}
			/>
		</HtmlSectionWrapper>
	);
};

const HtmlSection = withRouter(HtmlSectionFragment);

export default HtmlSection;
