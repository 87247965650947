import React, { createRef, useRef } from 'react';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import HeadoutPitch from 'Containers/common/headoutPitch';
import ProductPageBase, {
	connectProductPage,
} from 'Containers/common/productPage';

import AccordionContentSection from 'Components/common/accordionContentSection';
import Alert from 'Components/common/alert';
import BreadCrumb from 'Components/common/breadCrumb';
import { CalloutBannerTypes } from 'Components/common/callout/interface';
import Conditional from 'Components/common/conditional';
import type { SwiperRefActions } from 'Components/common/customSwiper/interface';
import FeedSectionHeader from 'Components/common/feedSectionHeader';
import MissingAnyInfo from 'Components/common/missingAnyInfo';
import PageLoader from 'Components/common/pageLoader';
import ProductPageContentSkeleton from 'Components/common/productPageContentSkeleton';
import RenderOneOf from 'Components/common/renderOneOf';
import DownloadAppSection from 'Components/desktop/downloadAppSection';
import ExperienceCategoryRating from 'Components/desktop/experienceCategoryRating';
import NavigationButtons from 'Components/desktop/navigationButtons';
import NearByCitiesCarousel from 'Components/desktop/nearByCitiesCarousel';
import type { TPoiData } from 'Components/desktop/operatingHoursTable/type';
import TopAttractionSection from 'Components/desktop/topAttractionSection';

import { useSwiperArrows } from 'Hooks/useSwiperArrows';
import {
	getExperiencePageEventCommonProperties,
	sendVariableToDataLayer,
	trackEvent,
} from 'Utils/analytics';
import {
	getAnalyticsFlowType,
	isTourGroupOpenDated,
	routeToBookingFlow,
} from 'Utils/bookingFlowUtils';
import { getCityCountryName } from 'Utils/cityUtils';
import { createSanitisedIdentifer, getAccordionIndex } from 'Utils/gen';
import HelmetUtilities from 'Utils/helmetUtils';
import {
	isCruisesItinerary as checkIfCruisesItinerary,
	isHOHOItinerary as checkIfHOHOItinerary,
} from 'Utils/itineraryUtils';
import {
	checkIfMultiVariantComboProduct,
	getAddressAsString,
	getBreadcrumbsForProductPage,
	getCancellationPolicyContent,
	getPrimaryCategoryId,
	getPrimaryCategoryName,
	getPrimaryCollectionId,
	getPrimaryCollectionName,
	getPrimarySubCategoryId,
	getPrimarySubCategoryName,
	getSuffixNameFromTag,
	getTicketValidityContent,
	hasComboVariants,
	hasMultipleAvailableVariants,
	shouldShowEntitiesInBreadcrumbs,
	shouldUseSuffixWithHypen,
} from 'Utils/productUtils';
import { getReviewsWithImageUrls } from 'Utils/reviewUtils';
import { smoothScroll } from 'Utils/scrollUtils';
import { sendAlertData } from 'Utils/subscriptionUtils';
import { getVariantFromUrl, removePageQuery } from 'Utils/urlUtils';

import { updateUserAttributes } from 'Actions/user';

import {
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
	PRODUCT_PAGE,
} from 'Constants/analytics';
import {
	ALERT_TYPE,
	ANALYTICS_FLOW_TYPE,
	QUERY_PARAM,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import fonts from 'Static/typography/fonts';
import TYPE_LABELS from 'Static/typography/labels';

const LongDescriptors = dynamic(
	() =>
		import(
			/* webpackChunkName: 'LongDescriptors' */ 'Components/common/descriptors/longDescriptors'
		),
);
const HtmlSection = dynamic(
	() =>
		import(
			/* webpackChunkName: 'HtmlSection' */ 'Components/common/htmlSection'
		),
);
const MapSection = dynamic(
	() =>
		import(
			/* webpackChunkName: 'MapSection' */ 'Components/common/mapSection'
		),
);
const ProductCardList = dynamic(
	() =>
		import(
			/* webpackChunkName: 'ProductCardList' */ 'Components/common/productCardList'
		),
);
const Summary = dynamic(
	() => import(/* webpackChunkName: 'Summary' */ 'Components/common/summary'),
);
const ThingsToDoSection = dynamic(
	() =>
		import(
			/* webpackChunkName: 'ThingsToDoSection' */ 'Components/common/thingsToDoSection'
		),
);
const ComboVariantCardList = dynamic(
	() =>
		import(
			/* webpackChunkName: 'ComboVariantCardList' */ 'Components/common/comboVariantCardList'
		),
);
const MultiMediaBanner = dynamic(
	() =>
		import(
			/* webpackChunkName: 'MultiMediaBanner' */ 'Components/desktop/bannerLayouts/multiMediaBanner'
		),
);
const SingleMediaBanner = dynamic(
	() =>
		import(
			/* webpackChunkName: 'SingleMediaBanner' */ 'Components/desktop/bannerLayouts/singleMediaBanner'
		),
);
const ReviewSection = dynamic(
	() =>
		import(
			/* webpackChunkName: 'ReviewSection' */ 'Components/desktop/reviewSection'
		),
);
const SelectedVariantBar = dynamic(
	() =>
		import(
			/* webpackChunkName: 'SelectedVariantBar' */ 'Components/desktop/selectedVariantBar'
		),
);
const SideBarAlert = dynamic(
	() =>
		import(
			/* webpackChunkName: 'SideBarAlert' */ 'Components/desktop/sideBarAlert'
		),
);
const OperatingHoursTable = dynamic(
	() =>
		import(
			/* webpackChunkName: 'OperatingHoursTable' */ 'Components/desktop/operatingHoursTable'
		),
	{ ssr: true },
);
const CalloutBanner = dynamic(
	import(
		/* webpackChunkName: 'CalloutBanner' */ 'Components/common/callout/calloutBanner'
	),
);
const SectionNavigator = dynamic(
	import(
		/* webpackChunkName: 'SectionNavigator' */ 'Components/desktop/sectionNavigator'
	),
);
const BookButtonCard = dynamic(
	() =>
		import(
			/* webpackChunkName: 'BookButtonCard' */ 'Components/desktop/bookButtonCard'
		),
);
const Itinerary = dynamic(
	() =>
		import(
			/* webpackChunkName: 'Itinerary' */ '@headout/espeon/components'
		).then(m => m.Itinerary),
	{
		ssr: false,
	},
);

const ProductPageWrapper = styled.div<{
	$isMultiLayout?: boolean;
	$hasMachineTranslatedContent?: boolean;
}>`
	position: relative;
	margin: auto;
	width: 75rem;
	font-family: ${fonts.HALYARD.TEXT};
	font-weight: ${fonts.FONT_WEIGHT.LIGHT};

	.short-summary {
		padding-bottom: 1.5rem;
		${({ $hasMachineTranslatedContent }) =>
			!$hasMachineTranslatedContent &&
			css`
				border-bottom: 1px solid ${colors.GREY.E2};
			`}
	}

	#reviews-section {
		scroll-margin-top: 8.125rem;
	}

	.variant-section {
		margin: 2.5rem 0;
		scroll-margin-top: 8.75rem;

		${({ $isMultiLayout }) =>
			!$isMultiLayout &&
			`
            .variant-card {
                width: 18.125rem;
            }
        `}
	}

	.itinerary-section {
		max-width: 49.5rem;
	}
`;

const MainContent = styled.div<{ $isMultiLayout?: boolean }>`
	display: grid;
	${({ $isMultiLayout }) =>
		$isMultiLayout
			? `
        grid-template-columns: 49.5rem auto;
        grid-template-areas:
            "top top"
            "details sidebar";
    `
			: `
        grid-template-columns: 49.5rem auto;
        grid-template-areas:
            "top sidebar"
            "details sidebar";
    `}

	grid-template-rows: auto 1fr;
	margin-bottom: 2.5rem;
`;

const TopWrapper = styled.div`
	grid-area: top;
	display: flex;
	flex-direction: column;
`;

const DetailsWrapper = styled.div`
	grid-area: details;

	.inclusions .content-body ul {
		list-style: none;
		padding-left: 0;

		li {
			position: relative;
			padding-left: 1.8rem;

			&::before {
				content: url('/static/svg/tick.svg');
				position: absolute;
				margin-top: 0.2rem;
				margin-left: -0.2rem;
				left: 0;
			}
		}
	}

	.exclusions .content-body ul {
		list-style: none;
		padding-left: 0;

		li {
			position: relative;
			padding-left: 1.8rem;

			&::before {
				content: url('/static/svg/close.svg');
				position: absolute;
				margin-top: 0.2rem;
				margin-left: -0.2rem;
				left: 0;
			}
		}
	}
`;

const SideBarWrapper = styled.div<{ $isMultiLayout?: boolean }>`
	grid-area: sidebar;
	justify-self: flex-end;

	${({ $isMultiLayout }) =>
		css`
			margin-top: ${$isMultiLayout ? 1.5 : 0.875}rem;
			width: 24rem;
		`}
`;

export const ProductTitleRatingsWrapper = styled.div<{
	$includeDescriptors?: boolean;
}>`
	display: flex;
	flex-direction: column;
	margin-bottom: 0.75rem;
	max-width: 62rem;

	@media (min-width: 768px) {
		margin-bottom: 1.5rem;
	}
`;

const StickyWrapper = styled.div<{ $isMultiLayout: boolean }>`
	position: sticky;
	top: 9.75rem;
`;

const SimilarProductsListWrapper = styled.div`
	width: 75rem;
`;

const FeedSectionContainer = styled.div`
	margin: 4.5rem 0;

	.top-attracions-section-container {
		padding: 0;

		.cities-list-v2-scroll-wrapper {
			margin-bottom: 0;
		}
	}
`;

const VariantListHeading = styled.h2`
	${getFontDetailsByLabel(TYPE_LABELS.HEADING_SMALL)};
	color: ${colors.GREY['44']};
	margin: 2.5rem 0 2rem;
`;

export const ProductName = styled.h1`
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
	color: ${colors.GREY['22']};
	${getFontDetailsByLabel(TYPE_LABELS.DISPLAY_SMALL)};
`;

class ProductPageElements extends ProductPageBase {
	stickySectionNavigationObserver: IntersectionObserver | null = null;
	state = {
		isCalendarOpen: false,
		showAppOnlyPopup: false,
		isAppOnlyExperimentRecorded: false,
		isButtonLoading: false,
		isSwitchedToOriginalLang: false,
		isContentTranslating: false,
		showMachineTranslatedCalloutBanner: false,
		showStickySectionNavigation: false,
		isSectionNavigatorInitialized: false,
		navigatorSections: [],
		isStickySectionNavigationObservableInitialized: false,
		hasScrolled: false,
	};

	constructor(props: any) {
		super(props);
		this.state = {
			isCalendarOpen: false,
			showAppOnlyPopup: false,
			isAppOnlyExperimentRecorded: false,
			isButtonLoading: false,
			isSwitchedToOriginalLang: false,
			isContentTranslating: false,
			showMachineTranslatedCalloutBanner: false,
			showStickySectionNavigation: false,
			isSectionNavigatorInitialized: false,
			navigatorSections: [],
			isStickySectionNavigationObservableInitialized: false,
			hasScrolled: false,
		};
	}

	componentDidMount() {
		const { location, product } = this.props;

		if (product) {
			this.preCalculate();
		}

		if (getVariantFromUrl(location) === 'UNSELECTED') {
			if (checkIfMultiVariantComboProduct(product)) {
				this.scrollToVariantSection();
			}
			removePageQuery(QUERY_PARAM.VARIANT_ID);
		}

		this.setMachineTranslateCalloutBannerVisibility();
		this.stickySectionNavigationObserver = new IntersectionObserver(
			this.handleStickySectionNavigationTrigger,
			{
				root: null,
				rootMargin: '-81px 0px 0px 0px',
				threshold: 0,
			},
		);

		this.sendAnalyticsEvents();
	}

	// @ts-expect-error TS(2416): Property 'componentDidUpdate' in type 'ProductPage... Remove this comment to see the full error message
	componentDidUpdate(prevProps, prevState) {
		// @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
		super.componentDidUpdate(prevProps, prevState);
		const { product, inventoryData } = this.props;
		const { inventoryData: prevInventoryData } = prevProps;
		const { isSwitchedToOriginalLang } = prevState;
		if (
			inventoryData &&
			inventoryData?.flowType !== prevInventoryData?.flowType
		)
			sendVariableToDataLayer({
				name: ANALYTICS_PROPERTIES.FLOW_TYPE,
				value: getAnalyticsFlowType(product),
			});

		if (product) {
			this.preCalculate();
			this.initialiseSectionNavigator(isSwitchedToOriginalLang);
		}

		const { hasScrolled } = this.state;
		const { search } = location;

		if (!hasScrolled && search.includes('scrollTo')) {
			const searchParams = new URLSearchParams(search);
			const sectionId = searchParams.get('scrollTo');

			if (!sectionId) return;

			const scrollElement = document.getElementById(sectionId);

			if (scrollElement) {
				const headerOffset = 132;
				const elementPosition =
					scrollElement.getBoundingClientRect().top;
				const offsetPosition =
					elementPosition + window.scrollY - headerOffset;

				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth',
				});

				this.setState({ hasScrolled: true });
			}
		}
	}

	componentWillUnmount() {
		this.stickySectionNavigationObserver?.disconnect();
	}

	initialiseSectionNavigator = (prevIsSwitchedToOriginalLang: boolean) => {
		const {
			isSectionNavigatorInitialized,
			isStickySectionNavigationObservableInitialized,
			isSwitchedToOriginalLang: currentIsSwitchedToOriginalLang,
		} = this.state;
		if (
			(!isSectionNavigatorInitialized &&
				!isStickySectionNavigationObservableInitialized) ||
			prevIsSwitchedToOriginalLang !== currentIsSwitchedToOriginalLang
		) {
			const navigatorSections = this.generateNavigationSections();
			const element = document.querySelector(
				'.media-container',
			) as HTMLDivElement;

			if (element) {
				this.stickySectionNavigationObserver?.observe(element);
			}

			this.setState((prevState: any) => ({
				...prevState,
				isSectionNavigatorInitialized: true,
				isStickySectionNavigationObservableInitialized: true,
				navigatorSections,
			}));
		}
	};

	handleStickySectionNavigationTrigger: IntersectionObserverCallback =
		entries => {
			entries.forEach(entry => {
				if (!entry.isIntersecting) {
					this.setState((prevState: any) => {
						return {
							...prevState,
							showStickySectionNavigation: true,
						};
					});
				} else {
					this.setState((prevState: any) => {
						return {
							...prevState,
							showStickySectionNavigation: false,
						};
					});
				}
			});
		};

	preCalculate = () => {
		const { product } = this.props;
		(this as any).address = getAddressAsString(product);
		(this as any).userReviews = getReviewsWithImageUrls(
			product?.topReviews,
		);
	};

	sendAnalyticsEvents = () => {
		let { product, currentCity } = this.props;

		// product might not exist on navigation from one product to another
		if (!product || !currentCity) return;

		const { id } = product;
		const { displayName: cityName, cityCode: currentCityCode } =
			currentCity;
		const primaryCollectionName = getPrimaryCollectionName(product);
		const primaryCollectionId = getPrimaryCollectionId(product);

		sendVariableToDataLayer({
			name: ANALYTICS_PROPERTIES.PRIMARY_COLLECTION_NAME,
			value: primaryCollectionName,
		});
		sendVariableToDataLayer({
			name: ANALYTICS_PROPERTIES.PRIMARY_COLLECTION_ID,
			value: primaryCollectionId,
		});
		sendVariableToDataLayer({
			name: ANALYTICS_PROPERTIES.HAS_COMBO_VARIANTS,
			value: hasComboVariants(product),
		});
		trackEvent({
			eventName: 'Experience Page Loaded',
			...getExperiencePageEventCommonProperties(product, currentCityCode),
		});
		updateUserAttributes({
			lastCityViewed: cityName,
			lastProductViewed: id,
		});
	};

	variantSection = createRef<HTMLDivElement>();

	scrollToVariantSection = () => {
		const { product, inventoryData } = this.props;
		const { id } = product;
		const variantSectionNode = this.variantSection.current;
		if (checkIfMultiVariantComboProduct(product) && inventoryData)
			trackEvent({
				eventName: 'Select Tickets CTA Clicked',
				'Tour Group ID': id,
				'Flow Type': getAnalyticsFlowType(product),
			});

		// avoiding Element.scrollIntoView ATM, since easing is not supported on Safari.
		smoothScroll(variantSectionNode, 500, () => {}, null, false, 150);
	};

	setIsButtonLoading = (state: boolean) => {
		this.setState({ isButtonLoading: state });
	};

	handleVariantBarClick = () => {
		// @ts-expect-error TS(2339): Property 'inventoryData' does not exist on type 'R... Remove this comment to see the full error message
		const { booking, lang, product } = this.props;
		const { id, selectedVariantId, selectedDate } = booking;
		let flowType;
		const isOpenDated = isTourGroupOpenDated(product);
		if (isOpenDated) {
			flowType = hasMultipleAvailableVariants(product)
				? ANALYTICS_FLOW_TYPE.OPEN_DATED_MULTI_VARIANT
				: ANALYTICS_FLOW_TYPE.OPEN_DATED_NO_VARIANT;
		}
		trackEvent({
			eventName: 'Check Availability Clicked',
			[ANALYTICS_PROPERTIES.SELECTED_DATE]: selectedDate,
			...getExperiencePageEventCommonProperties(product),
			...(isOpenDated && { 'Flow Type': flowType }),
		});
		routeToBookingFlow(
			id,
			booking,
			{
				lang,
				variantId: selectedVariantId,
			},
			true,
			product,
		);
	};

	showDateSelector = () => {
		this.setState({ isCalendarOpen: !this.state.isCalendarOpen });
	};

	getSideBarContent = () => {
		// @ts-expect-error TS(2339): Property 'cookies' does not exist on type 'Readonl... Remove this comment to see the full error message
		const { product, host, cookies, user, inventoryData, lang } =
			this.props;
		const { sortedInventoryDates } = inventoryData ?? {};
		const { id, listingPrice, city } = product;
		const { code } = city;
		let isInventoryAvailable = Boolean(listingPrice);
		if (sortedInventoryDates) {
			isInventoryAvailable =
				Boolean(listingPrice) && sortedInventoryDates?.length;
		}
		const { isCalendarOpen, isButtonLoading } = this.state;

		return (
			<>
				<RenderOneOf
					positionalConditions={[
						isInventoryAvailable,
						!isInventoryAvailable,
					]}
				>
					<BookButtonCard
						// @ts-expect-error TS(2322): Type '{ cookies: any; product: any; host: string; ... Remove this comment to see the full error message
						cookies={cookies}
						product={product}
						lang={lang}
						host={host}
						isButtonLoading={isButtonLoading}
						setIsButtonLoading={this.setIsButtonLoading}
						inventory={inventoryData}
						scrollToVariantSection={() =>
							this.scrollToVariantSection()
						}
						isCalendarOpen={isCalendarOpen}
						showDateSelector={this.showDateSelector}
					/>
					<SideBarAlert
						tourId={id}
						imageSvgSymbol='emailBox'
						alertHeader={strings.INVENTORY_ALERT.ALERT_HEADER}
						alertContent={strings.INVENTORY_ALERT.ALERT_CONTENT}
						buttonText={strings.INVENTORY_ALERT.BUTTON_TEXT}
						inputPlaceholder={
							strings.INVENTORY_ALERT.INPUT_PLACEHOLDER
						}
						buttonColor={colors.PINK}
						sendAlertData={sendAlertData}
						alertType={ALERT_TYPE.INVENTORY_NOT_AVAILABLE_ALERT}
						currentCityCode={code}
						user={user}
					/>
				</RenderOneOf>

				<HeadoutPitch
					isSwitchedToOriginalLang={
						this.state.isSwitchedToOriginalLang
					}
				/>
			</>
		);
	};

	getSidebarSection = (useMultiLayout: boolean) => (
		<StickyWrapper $isMultiLayout={useMultiLayout}>
			{this.getSideBarContent()}
		</StickyWrapper>
	);

	handleSectionNavigatorTabSelection = (activeTabId: string) => {
		const target = document.getElementById(activeTabId);

		if (target) {
			target.setAttribute('open', 'true');
			target.ariaExpanded = 'true';
		}
	};

	trackVariantSelection = ({ id, variantId, product }: any) => {
		trackEvent({
			eventName: 'Experience Variant Selected',
			'Tour Group ID': id,
			'Variant ID': variantId,
			'Flow Type': getAnalyticsFlowType(product),
		});
	};

	trackShowAllReviews = () => {
		trackEvent({
			eventName: 'Show All Reviews Clicked',
			...getExperiencePageEventCommonProperties(this.props.product),
		});
	};

	trackMissingInformation = (missingInfo: any) => {
		trackEvent({
			eventName: 'Missing Any Information Clicked',
			'Missing Information': missingInfo,
			...getExperiencePageEventCommonProperties(this.props.product),
		});
	};

	trackSectionViews = (
		sectionName: string,
		expanded: boolean,
		target: EventTarget,
	) => {
		const index = getAccordionIndex({
			target,
			selector: '.accordion-content',
		});
		if (index === -1) return;

		trackEvent({
			eventName: 'Experience Page Section Viewed',
			[ANALYTICS_PROPERTIES.SECTION]: sectionName,
			[ANALYTICS_PROPERTIES.EXPANDED]: expanded,
			[ANALYTICS_PROPERTIES.RANKING]: index + 1,
			...getExperiencePageEventCommonProperties(this.props.product),
		});
	};

	trackSectionInteraction = ({
		accordionState: action,
		extractedHeading,
		target,
	}: {
		accordionState: boolean;
		extractedHeading: string;
		target: EventTarget;
	}) => {
		const index = getAccordionIndex({
			target,
			selector: '.accordion-content',
		});
		if (index === -1) return;
		trackEvent({
			eventName: 'Experience Page Section Clicked',
			[ANALYTICS_PROPERTIES.SECTION]: extractedHeading,
			[ANALYTICS_PROPERTIES.ACTION]: !action ? 'Open' : 'Close',
			[ANALYTICS_PROPERTIES.RANKING]: index + 1,
		});
	};

	trackNavigationBreadcrumbsClick = (type: any) => {
		trackEvent({
			eventName: 'Navigation Breadcrumbs Clicked',
			Type: type,
			...getExperiencePageEventCommonProperties(this.props.product),
		});
	};

	trackRatingsWidgetClicks = () => {
		trackEvent({
			eventName: 'Ratings Widget Clicked',
			...getExperiencePageEventCommonProperties(this.props.product),
		});
	};

	trackImageGalleryMediaClick = (
		galleryType: 'Headout Gallery' | 'Guest Snapshots',
		isVideo: boolean,
	) => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.IMAGE_GALLERY.MEDIA_CLICKED,
			[ANALYTICS_PROPERTIES.TAB_STATE]: galleryType,
			'Is Video': isVideo,
			...getExperiencePageEventCommonProperties(this.props.product),
		});
	};

	trackImageGalleryTypeChange = (
		galleryType: 'Headout Gallery' | 'Guest Snapshots',
	) => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.IMAGE_GALLERY.OPENED,
			[ANALYTICS_PROPERTIES.TAB_STATE]: galleryType,
			...getExperiencePageEventCommonProperties(this.props.product),
		});
	};

	render() {
		const {
			product,
			reviews,
			currentCity,
			similarProductIds,
			query,
			// @ts-expect-error TS(2339): Property 'helmetFunctions' does not exist on type ... Remove this comment to see the full error message
			helmetFunctions,
			setBookingVariantId,
			variantId,
			// @ts-expect-error TS(2339): Property 'collectionIds' does not exist on type 'R... Remove this comment to see the full error message
			collectionIds,
			// @ts-expect-error TS(2339): Property 'categoriesAndSubCategoriesInfo' does not... Remove this comment to see the full error message
			categoriesAndSubCategoriesInfo,
			langDisplayName,
			poiInfo,
			itineraryData,
			isBot,
			citiesMap,
			languageCode,
		} = this.props;

		if (!product || !currentCity) {
			return <PageLoader />;
		}

		const {
			id,
			summary,
			shortSummary,
			highlights,
			faq,
			adhoc,
			ticketDeliveryInfo: ticketInfo,
			inclusions,
			exclusions,
			additionalInfo,
			startLocation,
			reviewsDetails,
			name,
			allTags,
			variants,
			imageUploads = [],
			cancellationPolicyV2: cancellationPolicy,
			reschedulePolicy,
			ticketValidity,
			experienceVideo,
			currency,
		} = product;

		const { cityCode, displayName: cityDisplayName } = currentCity;
		const primaryCollectionId = getPrimaryCollectionId(product);
		const primaryCategoryId = getPrimaryCategoryId(product);
		const primarySubCategoryId = getPrimarySubCategoryId(product);
		const primaryCollectionName = getPrimaryCollectionName(product);
		const primaryCategoryName = getPrimaryCategoryName(product);
		const primarySubCategoryName = getPrimarySubCategoryName(product);
		const country = getCityCountryName(citiesMap, currentCity?.cityCode);

		const { longitude, latitude } = startLocation;
		const { reviewsCount, averageRating, showRatings, reviewCountries } =
			reviewsDetails;
		const disableTranslate = this.shouldDisableTranslate();
		const { vendorSanitaryInfo, vendorSanitaryImages } = product;
		const { lang } = query;
		const { linkTexts, linkUrls, linkTitles, linkTypes } =
			getBreadcrumbsForProductPage({
				product,
				city: currentCity,
				lang,
				...shouldShowEntitiesInBreadcrumbs({
					product,
					categoriesAndSubCategoriesInfo,
				}),
			});
		const cancellationPolicyContent = getCancellationPolicyContent({
			cancellationPolicy,
			reschedulePolicy,
			ticketValidity,
		});
		const ticketValidityContent = getTicketValidityContent({
			ticketValidity,
		});
		const suffixProductName = getSuffixNameFromTag({
			allTags,
			useSuffixWithHypen: shouldUseSuffixWithHypen(primaryCollectionId),
			currentLang: lang,
		});
		const showTopAttractions = collectionIds?.filter(
			(id: number) => id !== primaryCollectionId,
		).length;
		const showOpSchedule =
			poiInfo?.length &&
			poiInfo.some((poiData: any) => poiData.operatingSchedules.length);

		const {
			isButtonLoading,
			isSwitchedToOriginalLang,
			showMachineTranslatedCalloutBanner,
			isContentTranslating,
			showStickySectionNavigation,
			isSectionNavigatorInitialized,
			navigatorSections,
		} = this.state;

		const useMultiLayout =
			(!!experienceVideo?.url && imageUploads?.length > 1) ||
			imageUploads.length >= 2;

		const stickySectionNavigatorEventConfig = {
			eventName:
				ANALYTICS_EVENTS.EXPERIENCE_PAGE
					.STICKY_SECTION_NAVIGATION_OPTION_CLICKED,
			additionalProperties: {
				...getExperiencePageEventCommonProperties(product),
			},
		};
		const showItinerarySection = this.shouldShowItinerarySection();
		const isHOHOItinerary = checkIfHOHOItinerary(itineraryData![0]);
		const isCruisesItinerary = checkIfCruisesItinerary(itineraryData![0]);
		const showSummarySection = this.shouldShowSummarySection(summary);
		const itinerarySectionHeading = this.getItinerarySectionHeading();

		return (
			<div>
				<HelmetUtilities {...helmetFunctions} />
				<Conditional if={isSectionNavigatorInitialized}>
					<SectionNavigator
						sections={navigatorSections}
						topOffset={206}
						isVisible={showStickySectionNavigation}
						sectionTabEventConfig={
							stickySectionNavigatorEventConfig
						}
						onTabSelection={this.handleSectionNavigatorTabSelection}
					/>
				</Conditional>
				<ProductPageWrapper
					$isMultiLayout={useMultiLayout}
					$hasMachineTranslatedContent={
						showMachineTranslatedCalloutBanner
					}
				>
					<BreadCrumb
						linkTexts={linkTexts}
						linkUrls={linkUrls}
						linkTitles={linkTitles}
						linkTypes={linkTypes}
						trackNavigationBreadcrumbsClick={
							this.trackNavigationBreadcrumbsClick
						}
						isProductPage
					/>

					<MainContent $isMultiLayout={useMultiLayout}>
						<TopWrapper className='top-wrapper'>
							<ProductTitleRatingsWrapper className='product-title-ratings'>
								<ExperienceCategoryRating
									primarySubCategory={
										product.primarySubCategory
									}
									reviewsDetails={reviewsDetails}
									trackWidgetClick={
										this.trackRatingsWidgetClicks
									}
								/>

								<ProductName data-qa-marker='product-name'>
									{name}
									{suffixProductName}
								</ProductName>
							</ProductTitleRatingsWrapper>

							<RenderOneOf
								positionalConditions={[
									useMultiLayout,
									!useMultiLayout,
								]}
							>
								<MultiMediaBanner
									productName={name + suffixProductName}
									imageUploads={imageUploads}
									reviews={reviews}
									experienceVideo={experienceVideo}
									onGalleryMediaClick={
										this.trackImageGalleryMediaClick
									}
									onGalleryChange={
										this.trackImageGalleryTypeChange
									}
								/>

								<SingleMediaBanner
									imageUpload={
										imageUploads && imageUploads[0]
									}
									experienceVideo={experienceVideo}
								/>
							</RenderOneOf>
						</TopWrapper>
						<DetailsWrapper className='details-wrapper'>
							<LongDescriptors
								product={product}
								lang={isSwitchedToOriginalLang ? 'en' : lang}
								poiInfo={poiInfo}
							/>
							<Conditional if={isContentTranslating}>
								<ProductPageContentSkeleton />
							</Conditional>
							<Conditional if={!isContentTranslating}>
								<Conditional if={shortSummary}>
									<div className='short-summary'>
										<HtmlSection
											// @ts-expect-error
											htmlContent={shortSummary}
											disableTranslate={disableTranslate}
										/>
									</div>
								</Conditional>
								<Conditional
									if={checkIfMultiVariantComboProduct(
										product,
									)}
								>
									<div
										ref={this.variantSection}
										className='variant-section'
									>
										<VariantListHeading>
											{strings.VP_TITLE_SELECT_PREFERENCE}
										</VariantListHeading>

										<ComboVariantCardList
											isMultiLayout={useMultiLayout}
											variants={variants}
											selectVariant={selectedVariantId => {
												this.trackVariantSelection({
													id,
													variantId:
														selectedVariantId,
													product,
												});
												setBookingVariantId(
													{
														id,
														variantId:
															selectedVariantId,
													},
													false,
												);
											}}
											// @ts-expect-error
											selectedVariantId={variantId}
										/>
									</div>
								</Conditional>

								<Conditional
									if={
										vendorSanitaryInfo &&
										vendorSanitaryImages
									}
								>
									<Alert
										info={vendorSanitaryInfo}
										images={vendorSanitaryImages}
									/>
								</Conditional>
								<Conditional
									if={showMachineTranslatedCalloutBanner}
								>
									<CalloutBanner
										type={
											CalloutBannerTypes.isMachineTranslatedContent
										}
										isSwitchedToOriginalLang={
											isSwitchedToOriginalLang
										}
										currentLangDisplayName={langDisplayName}
										onPrimaryCtaClick={
											this
												.handleMachineTranslatedCalloutPrimaryCTAClick
										}
									/>
								</Conditional>
								<Conditional if={highlights}>
									<AccordionContentSection
										heading={strings.PP_HEADING_HIGHLIGHTS}
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_HIGHLIGHTS
										}
										htmlContent={highlights}
										disableTranslate={disableTranslate}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
										isAccordionPanelOpen
									/>
								</Conditional>

								<Conditional if={inclusions}>
									<AccordionContentSection
										containerClass='inclusions'
										heading={strings.PP_HEADING_INCLUSIONS}
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_INCLUSIONS
										}
										htmlContent={inclusions}
										disableTranslate={disableTranslate}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
										isAccordionPanelOpen
									/>
								</Conditional>

								<Conditional if={exclusions}>
									<AccordionContentSection
										containerClass='exclusions'
										heading={strings.PP_HEADING_EXCLUSIONS}
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_EXCLUSIONS
										}
										htmlContent={exclusions}
										disableTranslate={disableTranslate}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
										isAccordionPanelOpen
									/>
								</Conditional>

								<Conditional
									if={!highlights && showSummarySection}
								>
									<Summary
										content={summary}
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_SUMMARY
										}
										disableTranslate={disableTranslate}
										sectionMaxHeights={350}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
										isAccordionPanelOpen
									/>
								</Conditional>

								<Conditional if={showItinerarySection}>
									<AccordionContentSection
										heading={itinerarySectionHeading}
										sectionLabel={itinerarySectionHeading}
										isAccordionPanelOpen
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
									>
										<div className='itinerary-section'>
											<Itinerary
												itineraryData={
													itineraryData as any
												}
												strings={{
													ITINERARY:
														strings.ITINERARY,
													formatString:
														strings.formatString,
													PREVIOUS: strings.PREVIOUS,
													NEXT: strings.NEXT,
													SEE_ALL:
														strings.CMN_SEE_ALL,
												}}
												lang={lang}
												trackEventFn={trackEvent}
												isMobile={false}
												isBot={isBot}
												isHohoItinerary={
													isHOHOItinerary
												}
												isCruisesItinerary={
													isCruisesItinerary
												}
												showTitle={false}
												additionalTrackingProperties={{
													city: currentCity.cityCode,
													categoryId:
														primaryCategoryId,
													subCategoryId:
														primarySubCategoryId,
													categoryName:
														primaryCategoryName,
													subCategoryName:
														primarySubCategoryName,
													collectionId:
														primaryCollectionId,
													collectionName:
														primaryCollectionName,
													country,
													currency: currency.currency,
													experienceName:
														product.name,
													tgid: product.id,
													pageType: isHOHOItinerary
														? 'Hop-On Hop-Off'
														: 'Day-Trips',
												}}
											/>
										</div>
									</AccordionContentSection>
								</Conditional>

								<Conditional if={cancellationPolicyContent}>
									<AccordionContentSection
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_CANCELLATION_POLICY
										}
										htmlContent={cancellationPolicyContent}
										disableTranslate={disableTranslate}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
										isAccordionPanelOpen
									/>
								</Conditional>

								<Conditional if={showRatings}>
									<div id='reviews-section'>
										<AccordionContentSection
											heading={strings.REVIEWS.plural}
											sectionLabel={
												PRODUCT_PAGE.PPRS_RATINGS_AND_REVIEWS
											}
											isAccordionPanelOpen
											trackSectionViews={
												this.trackSectionViews
											}
											trackSectionClick={
												this.trackSectionInteraction
											}
										>
											<ReviewSection
												product={product}
												params={query}
												userReviews={getReviewsWithImageUrls(
													product?.topReviews,
												)}
												reviewCountries={
													reviewCountries
												}
												averageRating={averageRating}
												showCount={5}
												collapsed
												compactHeader
												totalReviews={reviewsCount}
												onShowAllClick={
													this.trackShowAllReviews
												}
											/>
										</AccordionContentSection>
									</div>
								</Conditional>

								<Conditional if={ticketValidityContent}>
									<AccordionContentSection
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_TICKET_VALIDITY
										}
										// @ts-expect-error
										htmlContent={ticketValidityContent}
										disableTranslate={disableTranslate}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
									/>
								</Conditional>
								<Conditional
									if={highlights && showSummarySection}
								>
									<Summary
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_SUMMARY
										}
										content={summary}
										disableTranslate={disableTranslate}
										sectionMaxHeights={350}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
									/>
								</Conditional>
								<Conditional if={showOpSchedule}>
									<AccordionContentSection
										sectionLabel={
											PRODUCT_PAGE.PP_OPERATING_HOURS
										}
										heading={strings.OPERATING_HOURS}
										disableTranslate={disableTranslate}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
									>
										<>
											{poiInfo?.map(
												(
													poiData: TPoiData,
													index: number,
												) =>
													!!poiData.operatingSchedules
														.length && (
														<OperatingHoursTable
															data={poiData}
															key={
																poiData.id +
																index
															}
															lang={lang}
														/>
													),
											)}
										</>
									</AccordionContentSection>
								</Conditional>
								<Conditional if={faq}>
									<AccordionContentSection
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_FAQ
										}
										htmlContent={faq}
										disableTranslate={disableTranslate}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
									/>
								</Conditional>

								<Conditional if={ticketInfo}>
									<AccordionContentSection
										heading={strings.PP_HEADING_MY_TICKETS}
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_MY_TICKETS
										}
										htmlContent={ticketInfo}
										disableTranslate={disableTranslate}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
									/>
								</Conditional>
								<Conditional if={(this as any).address}>
									<AccordionContentSection
										heading={strings.PP_HEADING_WHERE}
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_WHERE
										}
										isAccordionPanelOpen
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
									>
										{/* @ts-expect-error TS(2741): Property 'onAddressClick' is missing in type '{ ad... Remove this comment to see the full error message */}
										<MapSection
											address={(this as any).address}
											disableTranslate={disableTranslate}
											latitude={latitude}
											longitude={longitude}
										/>
									</AccordionContentSection>
								</Conditional>

								<Conditional if={additionalInfo}>
									<AccordionContentSection
										heading={strings.PP_HEADING_ADD_INFO}
										sectionLabel={
											PRODUCT_PAGE.PP_HEADING_ADD_INFO
										}
										htmlContent={additionalInfo}
										disableTranslate={disableTranslate}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
									/>
								</Conditional>

								<Conditional if={adhoc}>
									<AccordionContentSection
										htmlContent={adhoc}
										disableTranslate={disableTranslate}
										trackSectionViews={
											this.trackSectionViews
										}
										trackSectionClick={
											this.trackSectionInteraction
										}
									/>
								</Conditional>
							</Conditional>
						</DetailsWrapper>

						<SideBarWrapper
							className='sidebar-wrapper'
							$isMultiLayout={useMultiLayout}
						>
							{this.getSidebarSection(useMultiLayout)}
						</SideBarWrapper>
					</MainContent>

					<Conditional if={similarProductIds?.length}>
						<FeedSectionContainer>
							<SimilarProductList
								similarProductIds={similarProductIds}
							/>
						</FeedSectionContainer>
					</Conditional>

					<Conditional if={showTopAttractions}>
						<FeedSectionContainer>
							<TopAttractionSection
								cityName={cityDisplayName}
								lang={lang}
								cityCode={cityCode}
								hasTransparentBackground
							/>
						</FeedSectionContainer>
					</Conditional>

					<Conditional if={categoriesAndSubCategoriesInfo?.length}>
						<ThingsToDoSection
							cityDisplayName={cityDisplayName}
							cityCode={cityCode}
							lang={lang}
							primaryCategory={primaryCategoryId}
							isOnExperiencePage
						/>
					</Conditional>
					<FeedSectionContainer>
						<NearByCitiesCarousel />
					</FeedSectionContainer>
				</ProductPageWrapper>

				<Conditional if={variantId}>
					<SelectedVariantBar
						isButtonLoading={isButtonLoading}
						setIsButtonLoading={this.setIsButtonLoading}
						selectedVariantId={variantId}
						product={product}
						onClick={this.handleVariantBarClick}
					/>
				</Conditional>
				<DownloadAppSection isMid={true} languageCode={languageCode} />
			</div>
		);
	}
}

const SimilarProductList = ({
	similarProductIds,
}: {
	similarProductIds: number[];
}) => {
	const productCarouselRef = useRef<SwiperRefActions>(null);
	const { showLeftArrow, showRightArrow, onSlideChanged } = useSwiperArrows();

	return (
		<SimilarProductsListWrapper>
			<FeedSectionHeader
				headingType='h2'
				title={strings.SIMILAR_EXPERIENCES}
			>
				<Conditional if={similarProductIds?.length > 4}>
					<NavigationButtons
						showLeftArrow={showLeftArrow}
						showRightArrow={showRightArrow}
						prevSlide={productCarouselRef.current?.prevSlide}
						nextSlide={productCarouselRef.current?.nextSlide}
					/>
				</Conditional>
			</FeedSectionHeader>
			<ProductCardList
				productIds={similarProductIds}
				showCityName={false}
				elementsToShow={4}
				elementsToSlide={2}
				productCarouselRef={productCarouselRef}
				onSlideChanged={onSlideChanged}
				parentIdentifier={createSanitisedIdentifer(
					strings.SIMILAR_EXPERIENCES,
				)}
			/>
		</SimilarProductsListWrapper>
	);
};

const ProductPage = connectProductPage(ProductPageElements);

export default withRouter(ProductPage);
