import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import DangerousHTML from 'Components/common/dangerousHTML';
import Accordion from 'Components/common/slices/accordion';

import { debounce } from 'Utils/gen';

import colors from 'Static/typography/colors';
import fonts from 'Static/typography/fonts';
import TYPE_LABELS from 'Static/typography/labels';

import { HtmlContent } from './htmlContent';

type SectionHeadingProps = {
	$size: string;
};

const Container = styled.div<{
	$size: string;
}>`
	margin-top: ${props => (props.$size === 'md' ? '0' : '1.5rem')};

	details {
		border-top: none;
		border-bottom: 1px solid ${colors.GREY_DS.G6};
		padding-bottom: 1.75rem;

		summary {
			padding-top: 0;

			${props => (props.$size === 'md' ? 'margin-top: 0;' : '')}
			&:focus-visible {
				outline: 0.125rem solid ${colors.BRAND_COLORS.PURPS};
				outline-offset: 0.125rem;
				border-radius: 0.25rem;
			}
		}

		&[open] {
			.heading {
				margin-bottom: 1rem;
			}
		}
	}
`;

const SectionHeading = styled.h2<SectionHeadingProps>`
	display: inline-block;
	color: ${colors.GREY['44']};
	font-family: ${fonts.HALYARD.DISPLAY};
	font-weight: ${fonts.FONT_WEIGHT.MEDIUM};
	letter-spacing: 0.8px;
	font-size: 1.5rem;

	&::first-letter {
		text-transform: uppercase;
	}

	${props =>
		props.$size === 'md' && getFontDetailsByLabel(TYPE_LABELS.HEADING_XS)};

	@media (max-width: 768px) {
		font-size: 1.3125rem;
	}
`;

type AccordionContentSectionProps = {
	heading?: string;
	sectionLabel?: string;
	htmlContent?: string;
	disableTranslate?: boolean;
	isAccordionPanelOpen?: boolean;
	children?: any;
	trackSectionViews?: (...args: any[]) => any;
	trackSectionClick?: (...args: any[]) => any;
	size?: string;
	containerClass?: string;
};

const AccordionContentSection = ({
	disableTranslate,
	htmlContent,
	children,
	heading,
	sectionLabel,
	isAccordionPanelOpen,
	trackSectionViews,
	trackSectionClick,
	size,
	containerClass,
}: AccordionContentSectionProps) => {
	const accordionRef = useRef();

	const extractedHeading = heading
		? heading
		: htmlContent?.match(/<h[1-6]>([^>]*)</)?.[1] || null;
	const trackClickEvent = ({
		accordionState,
		target,
	}: {
		accordionState: boolean;
		target: EventTarget;
	}) => {
		trackSectionClick?.({
			accordionState,
			target,
			extractedHeading: sectionLabel ?? extractedHeading,
		});
	};

	useEffect(() => {
		let hasFiredOnce = false;
		const observer = new IntersectionObserver(
			// @ts-expect-error TS(7031): Binding element 'entry' implicitly has an 'any' ty... Remove this comment to see the full error message
			debounce(([entry]) => {
				if (entry.isIntersecting && !hasFiredOnce) {
					hasFiredOnce = true;
					trackSectionViews?.(
						sectionLabel ?? extractedHeading,
						isAccordionPanelOpen ?? false,
						entry.target,
					);
				}
			}, 300),
		);
		// @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
		observer.observe(accordionRef.current);

		return () => observer.disconnect();
	}, []);

	return (
		<Container
			// @ts-expect-error TS(2769): No overload matches this call.
			ref={accordionRef}
			$size={size as string}
			className={classNames('accordion-content', containerClass)}
		>
			<Accordion
				heading={
					<SectionHeading
						// @ts-expect-error TS(2769): No overload matches this call.
						$size={size}
					>
						<DangerousHTML
							html={extractedHeading?.toString()}
							className={`content-html no-translate`}
						/>
					</SectionHeading>
				}
				isAccordionPanelOpen={isAccordionPanelOpen}
				trackClickEvent={trackClickEvent}
				content={
					htmlContent ? (
						<HtmlContent
							disableTranslate={disableTranslate}
							htmlContent={htmlContent}
							removeFirstHeading={!heading}
						/>
					) : (
						children
					)
				}
				renderAsChildren
			/>
		</Container>
	);
};

export default AccordionContentSection;
