import { createPortal } from 'react-dom';
import Link from 'next/link';

import RenderOneOf from 'Components/common/renderOneOf';

import { IDropDownProps } from '../interface';
import {
	StyledBreadCrumbDropdown,
	StyledBreadCrumbDropdownItem,
} from '../style';

const DropDown = ({
	size,
	linkTexts,
	linkUrls,
	showMoreLinks,
	linkTitles,
	posX,
	posY,
}: IDropDownProps) => {
	return createPortal(
		<StyledBreadCrumbDropdown
			onMouseEnter={showMoreLinks}
			$posX={posX}
			$posY={posY}
		>
			{linkTexts.slice(2, size - 3).map((text, oldIndex) => {
				const index = oldIndex + 2;
				const linkUrl = linkUrls[index];
				const linkTitle = linkTitles ? linkTitles[index] : '';

				return (
					<RenderOneOf
						positionalConditions={[!linkUrl, linkUrl]}
						noDefault
					>
						<StyledBreadCrumbDropdownItem title={linkTitle}>
							{text}
						</StyledBreadCrumbDropdownItem>
						<Link href={linkUrl} passHref legacyBehavior>
							<StyledBreadCrumbDropdownItem
								as={'a'}
								className='block'
								title={linkTitle}
							>
								{text}
							</StyledBreadCrumbDropdownItem>
						</Link>
					</RenderOneOf>
				);
			})}
		</StyledBreadCrumbDropdown>,
		document.getElementById('modal-root')!,
	);
};

export default DropDown;
