import React from 'react';
import Carousel from 'nuka-carousel';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import HtmlSection from 'Components/common/htmlSection';
import Modal from 'Components/common/modalRevamped';

import { CrossSvg, ShieldSvg } from 'Assets/svg/productPage';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import fonts from 'Static/typography/fonts';

const Wrapper = styled.div`
	display: grid;
	padding: 1rem;
	border-radius: 0.75rem;
	background: ${colors.LIGHT_BLUE};
	color: ${colors.GREY['44']};
	grid-row-gap: 0.5rem;
	grid-column-gap: 0.9375rem;
	grid-template-columns: min-content auto;
	grid-template-areas: 'icon title' 'alert-content alert-content';
	margin: 2rem 0;

	@media (max-width: 768px) {
		.product-alert-modal-content {
			width: 100% !important;
			min-height: 200px !important;
		}
		.product-alert-modal-control {
			align-items: flex-end !important;
		}
	}
`;

const CloseButton = styled.div`
	height: 1.5rem;
	width: 1.5rem;
	z-index: 2;
	position: absolute;
	top: 0;
	right: 0;
	margin: 0.5rem;
`;

const AlertIcon = styled.div`
	grid-area: icon;
	width: 1rem;
	height: 1.25rem;
`;

const AlertTitle = styled.div`
	font-family: ${fonts.HALYARD.DISPLAY};
	grid-area: title;
	font-size: 0.9375rem;
	font-weight: 500;
	align-self: center;
`;

const AlertDescription = styled.div`
	grid-area: alert-content;
	width: 85%;
	font-size: 0.875rem;
	line-height: 1.375rem;

	@media (max-width: 768px) {
		font-size: 0.75rem;
		width: auto;
	}
`;

const AlertReadMore = styled.div`
	display: inline-block;
	margin-left: 0.25rem;
	font-size: 0.75rem;
	text-decoration: underline;
	cursor: pointer;
`;

const ProductAlertModalWrapper = styled.div`
	display: grid;
	height: 100%;
	width: 100%;
	grid-template-columns: 1.5fr 2fr;
	grid-template-areas: 'images content';
	grid-column-gap: 1.25rem;
	color: ${colors.GREY['44']};

	@media (max-width: 768px) {
		max-height: 100vh;
		display: block;
	}

	.info {
		font-size: 0.75rem;
	}
`;

const ModalImages = styled.div`
	grid-area: images;
	max-height: 522px;

	img {
		max-height: 522px;
		object-fit: cover;
	}

	@media (max-width: 768px) {
		img {
			max-height: 200px;
		}
	}
`;

const ModalContent = styled.div`
	grid-area: content;
	align-self: center;
	padding: 1.75rem 1rem;

	@media (max-width: 768px) {
		width: 100vw;
	}
`;

const ContentTitle = styled.div`
	font-family: ${fonts.HALYARD.DISPLAY};
	font-weight: 500;
	font-size: 1.375rem;
	margin-bottom: 1rem;
`;

type Props = {
	info?: string;
	images?: any[];
};

const Alert = ({ images, info }: Props) => {
	const [modalActive, setModalActive] = React.useState(false);

	const toggleModal = () => {
		setModalActive(active => !active);
	};

	return (
		<Wrapper>
			{modalActive ? (
				<Modal
					open={modalActive}
					onRequestClose={toggleModal}
					containerClassName='product-alert-modal-control'
					contentClassName='product-alert-modal-content'
				>
					<CloseButton onClick={toggleModal}>
						<CrossSvg />
					</CloseButton>
					<ProductAlertModalWrapper>
						<ModalImages>
							<Carousel
								renderCenterLeftControls={() => null}
								renderCenterRightControls={() => null}
							>
								{/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
								{images.map((image, index) => {
									return (
										<img
											key={index}
											src={image}
											alt='sanitary-info'
										/>
									);
								})}
							</Carousel>
						</ModalImages>
						<ModalContent>
							<ContentTitle>
								{strings.SANITARY_ALERT.TITLE}
							</ContentTitle>
							<div className='info'>
								{/* @ts-expect-error TS(2786): 'HtmlSection' cannot be used as a JSX component. */}
								<HtmlSection htmlContent={info} />
							</div>
						</ModalContent>
					</ProductAlertModalWrapper>
				</Modal>
			) : null}
			<AlertIcon>
				<ShieldSvg />
			</AlertIcon>
			<AlertTitle>{strings.SANITARY_ALERT.TITLE}</AlertTitle>
			<AlertDescription>
				{strings.SANITARY_ALERT.DESCRIPTION}{' '}
				<AlertReadMore onClick={toggleModal}>
					{strings.SANITARY_ALERT.READ_MORE}
				</AlertReadMore>
			</AlertDescription>
		</Wrapper>
	);
};

export default Alert;
