import { ProductPageContentSkeletonWrapper } from 'Components/common/productPageContentSkeleton/styles';
import SkeletonLoader from 'Components/common/skeletonLoader';

import PlatformUtils from 'Utils/platformUtils';

const ProductPageContentSkeleton = () => {
	const isMobile = !PlatformUtils.isDesktop();
	const getSkeletonProps = (props: {
		width?: string;
		height: string;
		margin?: string;
		borderRadius?: string;
	}) => {
		return {
			...props,
			width: props.width ?? '100%',
			margin: props.margin ?? '0',
			borderRadius: props.borderRadius ?? '4',
		};
	};
	return (
		<ProductPageContentSkeletonWrapper>
			<SkeletonLoader
				{...getSkeletonProps({ height: isMobile ? '60px' : '56px' })}
			/>
			<SkeletonLoader
				{...getSkeletonProps({ height: isMobile ? '56px' : '48px' })}
			/>
			{Array.from({ length: 5 }).map((_item, index) => {
				return (
					<div
						className='accordion-skeleton'
						key={`skeleton-${index}`}
					>
						<SkeletonLoader
							{...getSkeletonProps({
								height: '28px',
								width: isMobile ? '185px' : '300px',
								margin: '0 0 2.4rem 0',
							})}
						/>
						<SkeletonLoader
							{...getSkeletonProps({
								height: isMobile ? '180px' : '196px',
							})}
						/>
					</div>
				);
			})}
		</ProductPageContentSkeletonWrapper>
	);
};

export default ProductPageContentSkeleton;
