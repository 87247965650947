import { useEffect, useRef } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import colors from 'Static/typography/colors';
import fonts from 'Static/typography/fonts';

import DangerousHTML from './dangerousHTML';

const HtmlContentWrapper = styled.div`
	.content-html {
		margin-top: 0.5rem;
		width: 90%;
		color: ${colors.GREY['44']};
		font-weight: ${fonts.FONT_WEIGHT.LIGHT};

		h4,
		h3,
		h2 {
			font-family: ${fonts.HALYARD.DISPLAY};
			letter-spacing: 0.0375rem;
			color: ${colors.GREY['44']};
			font-size: 1.125rem;
			font-weight: 500;
			margin-bottom: 1rem;

			:first-child {
				margin-top: 1rem;
			}
		}

		p,
		li,
		a {
			font-size: 1.0625rem;
		}

		li {
			line-height: 2rem;
			margin-bottom: 0.5rem;

			strong {
				display: inline;
			}
		}

		ul {
			list-style: unset;
			padding: 0 1.25rem;

			:not(:last-child) {
				margin-bottom: 1rem;
			}
		}

		p {
			line-height: 1.75rem;
			margin-bottom: 0.625rem;

			:last-child {
				margin-bottom: 0;
			}

			:first-child > strong {
				margin-top: 0.5rem;
			}

			strong {
				display: inline;
			}
		}

		strong {
			font-family: ${fonts.HALYARD.DISPLAY};
			letter-spacing: 0.0375rem;
			display: block;
			margin-top: 1.5rem;
			margin-bottom: 0.625rem;

			+ br {
				display: none;
			}
		}

		a {
			text-decoration: underline;
			color: ${colors.CANDY_1};
		}

		div {
			max-width: 55rem;
			align-self: stretch;
		}

		iframe {
			max-width: 100%;
		}
	}

	@media (max-width: 768px) {
		.content-html {
			width: 100%;

			ul {
				padding: 0 1.125rem;
			}

			p,
			li,
			a {
				font-size: 0.9375rem;
				line-height: 1.375rem;
			}

			li {
				line-height: 1.5rem;
			}

			p {
				margin-bottom: 0.75rem;
			}

			h4,
			h3,
			h2,
			strong {
				font-size: 0.9375rem;
				margin-bottom: 0.5rem;
				font-weight: 500;
			}

			div {
				flex-wrap: wrap;
				border-right: none !important;
				max-width: calc(100vw - 3rem);
			}
		}
	}
`;

export const HtmlContent = ({
	// @ts-expect-error TS(7031): Binding element 'disableTranslate' implicitly has ... Remove this comment to see the full error message
	disableTranslate,
	// @ts-expect-error TS(7031): Binding element 'htmlContent' implicitly has an 'a... Remove this comment to see the full error message
	htmlContent,
	removeFirstHeading = true,
}) => {
	const contentWrapperRef = useRef(null);
	const className = disableTranslate ? 'notranslate' : 'translate';
	const whiteSpaceStrippedContent = htmlContent?.replace(/[\s]+/g, ' ') ?? '';
	const finalHtml = removeFirstHeading
		? whiteSpaceStrippedContent.replace(/<h[1-6]>([^<]*)<\/h[1-6]>/, '')
		: whiteSpaceStrippedContent;

	useEffect(() => {
		if (!contentWrapperRef?.current) return;
		(contentWrapperRef.current as any)
			.querySelectorAll('.content-html a')
			.forEach((el: any) => el.setAttribute('target', '_blank'));
	}, []);

	return (
		<HtmlContentWrapper ref={contentWrapperRef}>
			<DangerousHTML
				html={finalHtml}
				className={`content-html ${className}`}
			/>
		</HtmlContentWrapper>
	);
};
