import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { SectionHeaderRightContainer } from 'Containers/desktop/citiesFeedPage';

import CityCardList from 'Components/common/cityCardListV2';
import Conditional from 'Components/common/conditional';
import FeedSectionHeader from 'Components/common/feedSectionHeader';
import { CarouselRefActions } from 'Components/common/lateralCarousel';
import NavigationButtons from 'Components/desktop/navigationButtons';
import ViewAllLink from 'Components/desktop/viewAllLink';

import { useSwiperArrows } from 'Hooks/useSwiperArrows';
import { getCitiesHref, getCitiesUrl } from 'Utils/cityUtils';
import { getCurrentLanguageCode, getNearByCityCodes } from 'Utils/stateUtils';

import { strings } from 'Constants/strings';

type TProps = {
	onScrollIntoView?: (ref: any) => void;
};

const NearByCitiesCarousel = ({ onScrollIntoView }: TProps) => {
	const citiesCarouselRef = useRef<CarouselRefActions>(null);
	const { showLeftArrow, showRightArrow, onSlideChanged } = useSwiperArrows();

	const { lang, nearByCityCodes } = useSelector(state => {
		return {
			lang: getCurrentLanguageCode(state),
			nearByCityCodes: getNearByCityCodes(state),
		};
	});

	if (!nearByCityCodes?.length) return null;
	return (
		<>
			<FeedSectionHeader
				headingType={'h2'}
				title={strings.NEARBY_CITIES_TO_EXPLORE}
				onScrollIntoView={onScrollIntoView}
			>
				<SectionHeaderRightContainer>
					<ViewAllLink
						href={getCitiesHref(lang)}
						to={getCitiesUrl(lang)}
					/>
					<Conditional if={nearByCityCodes?.length > 6}>
						<NavigationButtons
							showLeftArrow={showLeftArrow}
							showRightArrow={showRightArrow}
							prevSlide={citiesCarouselRef.current?.prevSlide}
							nextSlide={citiesCarouselRef.current?.nextSlide}
						/>
					</Conditional>
				</SectionHeaderRightContainer>
			</FeedSectionHeader>
			<CityCardList
				paramLang={lang}
				preCalculatedCityCodes={nearByCityCodes}
				isNearByCitiesSection
				citiesCarouselRef={citiesCarouselRef}
				onSlideChanged={onSlideChanged}
			/>
		</>
	);
};

export default NearByCitiesCarousel;
