/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import colors from 'Static/typography/colors';

export const ProductPageContentSkeletonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.5rem;

	.accordion-skeleton {
		border-bottom: 1px solid ${colors.GREY.E2};
		padding-bottom: 1.5rem;

		&:last-child {
			border-bottom: none;
		}
	}
`;
