import { isServer } from './envUtils';

const requestAnimFrame = () => {
	const shimFunc = (callback: any, _element: any) => {
		window.setTimeout(callback, 1000 / 60);
	};
	return (
		window.requestAnimationFrame ||
		(window as any).webkitRequestAnimationFrame ||
		(window as any).mozRequestAnimationFrame ||
		// @ts-expect-error TS(2551): Property 'oRequestAnimationFrame' does not exist o... Remove this comment to see the full error message
		window.oRequestAnimationFrame ||
		// @ts-expect-error TS(2551): Property 'msRequestAnimationFrame' does not exist ... Remove this comment to see the full error message
		window.msRequestAnimationFrame ||
		shimFunc
	);
};

export default isServer() ? () => {} : requestAnimFrame.bind(window)();
