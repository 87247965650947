import * as React from 'react';

import { css, cx } from '@headout/pixie/css';

const chevronRightStyles = css({
	width: '0.8025rem',
	height: '0.8025rem',
});

export const CloseSvg = (props: Record<string, any>) => {
	return (
		<svg viewBox='0 0 24 24' {...props}>
			<path
				d='M21.86 23.352l-9.943-9.943-9.86 9.86-1.41-1.408L10.509 12 .648 2.14 2.056.73l9.861 9.861L21.861.648l1.409 1.408L13.326 12l9.944 9.944z'
				fill='#BABABA'
				fillRule='evenodd'
			/>
		</svg>
	);
};

export const ChevronRight = ({ className = '', ...props }: any) => {
	return (
		<svg
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={cx(chevronRightStyles, className || '')}
			{...props}
		>
			<path
				d='M4.66666 14.6667L11.3333 8.00004L4.66666 1.33337'
				stroke={props.fill || 'currentColor'}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export const BadgeSvg = () => (
	<svg
		width='8'
		height='16'
		viewBox='0 0 8 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M6.14972 0.849168C6.61333 0.310087 7.28898 0 8 0V16C7.28898 16 6.61333 15.6899 6.14972 15.1508L1.68226 9.95612C0.715002 8.8314 0.715002 7.1686 1.68226 6.04388L6.14972 0.849168Z'
			fill='#078842'
		/>
	</svg>
);
