/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const ProductPageBreadCrumbWrapperCss = css`
	margin: 1.5rem auto 0.5rem;
	width: 75rem;
`;

export const StyledBreadCrumbWrapper = styled.ol<{ $isProductPage?: boolean }>`
	display: flex;
	${({ $isProductPage }) => $isProductPage && ProductPageBreadCrumbWrapperCss}
	@media (max-width: 768px) {
		scrollbar-width: none;
		-ms-overflow-style: none;
		padding: 0 1.5rem;
		overflow-x: auto;
		overflow-y: hidden;
		&::-webkit-scrollbar {
			display: none;
		}
	}
`;

export const StyledBreadCrumb = styled.li`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 0.125rem 0;
	svg.breadcrumb-icon {
		height: 0.625rem;
		margin: 0.1875rem 0.5rem 0 0.5rem;
		color: ${colors.GREY_DS.G3};
		@media (max-width: 768px) {
			height: 0.5625rem;
		}
	}
`;

export const StyledBreadCrumbText = styled.div<{ $underline?: boolean }>`
	color: ${colors.GREY_DS.G3};
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)};
	text-decoration: ${({ $underline }) => $underline && 'underline'};
	&.breadcrumb-ellipsis,
	&.breadcrumb-link {
		cursor: pointer;
	}
	&.breadcrumb-link {
		color: ${colors.GREY_DS.G3};
		text-decoration: ${({ $underline }) => $underline && 'underline'};
		transition: color 0.3s;
		&:hover {
			color: ${colors.BRAND_COLORS.PURPS};
		}
	}
	&.fade-text {
		cursor: default;
	}
	@media (max-width: 768px) {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)};
		width: max-content;
	}
`;

export const StyledBreadCrumbDropdown = styled.div<{
	$posX?: number;
	$posY?: number;
}>`
	position: absolute;
	z-index: 1000001;
	padding: 0.625rem;
	border-radius: 4px;
	top: ${({ $posY }) => `${$posY}px`};
	left: ${({ $posX }) => `${$posX}px`};
	background-color: ${colors.WHITE};
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	border: 1px solid #e2e2e2;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(0, 0, 0, 0.1);
`;

export const StyledBreadCrumbDropdownItem = styled.div`
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)};
	cursor: pointer;
	width: 100%;
	padding: 0.125rem;
`;
