import { PAYMENT_METHODS_CONTAINER_ID } from 'Constants/constants';

import requestAnimationFrame from './requestAnimFrameShimLayer';
/* eslint-disable */
// Get the top position of an element in the document
var getTop = function (element: any, start: any) {
	// return value of html.getBoundingClientRect().top ... IE : 0, other browsers : -pageYOffset
	if (element?.nodeName === 'HTML') return -start;
	return element.getBoundingClientRect().top + start;
};

var getLeft = function (element: any, start: any) {
	if (element?.nodeName === 'HTML') return -start;
	return element.getBoundingClientRect().left + start;
};
// ease in out function thanks to:
// http://blog.greweb.fr/2012/02/bezier-curve-based-easing-functions-from-concept-to-implementation/
var easeInOutCubic = function (t: any) {
	return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
};

// calculate the scroll position we should be in
// given the start and end point of the scroll
// the time elapsed from the beginning of the scroll
// and the total duration of the scroll (default 500ms)
// @ts-expect-error TS(7006): Parameter 'start' implicitly has an 'any' type.
var position = function (start, end, elapsed, duration) {
	if (elapsed > duration) return end;
	return start + (end - start) * easeInOutCubic(elapsed / duration); // <-- you can change the easing funtion there
	// return start + (end - start) * (elapsed / duration); // <-- this would give a linear scroll
};

// we use requestAnimationFrame to be called by the browser before every repaint
// if the first argument is an element then scroll to the top of this element
// if the first argument is numeric then scroll to this location
// if the callback exist, it is called when the scrolling is finished
// if context is set then scroll that element, else scroll window
export const smoothScroll = function (
	el: any,
	duration: any,
	callback: any,
	context: any,
	horizontal = false,
	offset = 0,
) {
	duration = duration || 500;
	context = context || window;
	var start = horizontal
		? context.scrollLeft || window.pageXOffset
		: context.scrollTop || window.pageYOffset;

	if (typeof el === 'number') {
		// @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
		var end = parseInt(el);
	} else {
		// @ts-expect-error TS(2403): Subsequent variable declarations must have the sam... Remove this comment to see the full error message
		var end = horizontal ? getLeft(el, start) : getTop(el, start);
	}
	end -= offset;

	var clock = Date.now();
	var step = function () {
		var elapsed = Date.now() - clock;
		if (context !== window) {
			if (horizontal) {
				context.scrollLeft = position(start, end, elapsed, duration);
			} else {
				context.scrollTop = position(start, end, elapsed, duration);
			}
		} else {
			if (horizontal) {
				window.scroll(position(start, end, elapsed, duration), 0);
			} else {
				window.scroll(0, position(start, end, elapsed, duration));
			}
		}

		if (elapsed > duration) {
			if (typeof callback === 'function') {
				callback(el);
			}
		} else {
			requestAnimationFrame(step);
		}
	};
	step();
};

/* @deprecated */
// @ts-expect-error TS(7006): Parameter 'callback' implicitly has an 'any' type.
export const scrollTo = (callback, scrollDuration = 150, toValue = 0) => {
	const scrollFrom = document.body.scrollTop;
	const scrollTo = toValue;
	const scrollDiff = scrollFrom - scrollTo;

	const oldTimestamp = performance.now();
	const step = (newTimestamp: any) => {
		const easeInOutQuad = (t: any) =>
			t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

		const timeRatio = (newTimestamp - oldTimestamp) / scrollDuration;
		const easingFactor = easeInOutQuad(timeRatio);

		if (timeRatio >= 1) window.scrollTo(0, toValue);
		if (document.body.scrollTop === toValue) {
			if (callback) {
				callback();
			}
			return;
		}
		window.scrollTo(0, scrollFrom - scrollDiff * easingFactor);
		requestAnimationFrame(step);
	};
	requestAnimationFrame(step);
};

// @ts-expect-error TS(7023): 'topPosition' implicitly has return type 'any' bec... Remove this comment to see the full error message
export const topPosition = domElement => {
	if (!domElement) {
		return 0;
	}
	return domElement.offsetTop + topPosition(domElement.offsetParent);
};

export const removeBodyScroll = (remove: any) => {
	if (remove === true) {
		document.body.style.overflow = 'hidden';
		document.body.style.top = `-${window.pageYOffset}px`; // done for safari browser in iphone
		document.body.style.position = 'fixed'; // done for safari browser in iphone
	} else {
		// @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
		document.body.style.overflow = null;
		// @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
		document.body.style.top = null;
		// @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
		document.body.style.position = null;
	}
};

export const scrollToFirstInputError = ({
	isMobile = false,
}: {
	isMobile: boolean;
}) => {
	const firstInputErrorField = document.querySelector('.input-error');
	if (firstInputErrorField) {
		smoothScroll(
			firstInputErrorField,
			250,
			() => {
				(firstInputErrorField as any).focus();
			},
			null,
			false,
			isMobile ? 250 : 200,
		);
	}
};

export const scrollToPaymentMethodsContainer = ({
	isMobile = false,
}: {
	isMobile: boolean;
}) => {
	const paymentMethodsContainer = document?.getElementById(
		PAYMENT_METHODS_CONTAINER_ID,
	);

	if (paymentMethodsContainer) {
		smoothScroll(
			paymentMethodsContainer,
			250,
			() => {},
			null,
			false,
			isMobile ? 250 : 200,
		);
	}
};

export const smoothScrollToWithLinearEasing = (
	target: HTMLElement,
	leftPosition: number,
	duration: number,
) => {
	const start = target.scrollLeft;
	const distance = leftPosition - start;
	const startTime = performance.now();

	function animate(time: number): void {
		const elapsed = time - startTime;
		const progress = elapsed / duration;
		const easedProgress = Math.min(1, progress);

		target.scrollLeft = Math.round(start + distance * easedProgress);

		if (progress < 1) {
			requestAnimationFrame(animate);
		}
	}

	requestAnimationFrame(animate);
};

export const moveContainer = (
	parentContainer: HTMLElement | typeof window,
	childElement: HTMLElement,
	offset = 0,
) => {
	const childPositionRelativeToParent = childElement?.offsetTop - offset;
	parentContainer.scrollTo({
		top: childPositionRelativeToParent,
		behavior: 'smooth',
	});
};

export const autoScrollToView = (
	element: HTMLElement,
	condition: boolean,
	delay = 0,
) => {
	let timeoutId: ReturnType<typeof setTimeout> | null = null;

	if (condition && element) {
		timeoutId = setTimeout(() => {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}, delay);
	}

	return () => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
	};
};

export const isElementBelowViewportCenter = (el: HTMLElement) => {
	const viewportCenter = window.innerHeight / 2;
	const { bottom: elBottom } = el.getBoundingClientRect();

	return elBottom > viewportCenter;
};
