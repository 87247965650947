import React, {
	MutableRefObject,
	useCallback,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import Link from 'next/link';

import DropDown from 'Components/common/breadCrumb/components/dropdown';
import Conditional from 'Components/common/conditional';
import RenderOneOf from 'Components/common/renderOneOf';

import { ChevronRight } from 'Assets/svg/productCard';

import {
	IBreadCrumbProps,
	IDropdownPosition,
	IInnerTextOrLinkProps,
} from './interface';
import {
	StyledBreadCrumb,
	StyledBreadCrumbText,
	StyledBreadCrumbWrapper,
} from './style';

const InnerTextOrLink = ({
	index,
	onBreadcrumbClick,
	linkUrl,
	size,
	chevronLimit,
	text,
	underline,
}: IInnerTextOrLinkProps) => {
	return (
		<StyledBreadCrumb
			className='link-wrapper'
			key={index}
			onClick={() => onBreadcrumbClick(index)}
			onTouchStart={() => onBreadcrumbClick(index)}
		>
			<RenderOneOf positionalConditions={[!linkUrl, linkUrl]} noDefault>
				<StyledBreadCrumbText
					className={`${index === size - 1 ? 'fade-text' : ''}`}
				>
					{text}
				</StyledBreadCrumbText>
				<Link href={linkUrl!} passHref legacyBehavior>
					<StyledBreadCrumbText
						as={'a'}
						className={`block breadcrumb-link`}
						$underline
						aria-current={underline ? 'location' : 'false'}
					>
						{text}
					</StyledBreadCrumbText>
				</Link>
			</RenderOneOf>
			<Conditional if={index < chevronLimit}>
				<ChevronRight
					className={'breadcrumb-icon'}
					aria-hidden='true'
				/>
			</Conditional>
		</StyledBreadCrumb>
	);
};

const BreadCrumb = ({
	linkTexts,
	linkUrls,
	linkTitles,
	linkTypes,
	trackNavigationBreadcrumbsClick,
	isProductPage,
	isMobile,
}: IBreadCrumbProps) => {
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const ref = useRef<HTMLOListElement | null>(null);

	const ellipsisRef = useRef<HTMLDivElement | null>(null);
	const dropDownTimerRef: MutableRefObject<NodeJS.Timeout | null> =
		useRef(null);
	const [dropdownPosition, setDropdownPosition] =
		useState<IDropdownPosition>();

	const size = Math.min(linkTexts.length, linkUrls.length);

	const onWindowSizeChange = () => {
		if (!ellipsisRef || !ellipsisRef.current || !ref || !ref.current)
			return;
		const { height, bottom } = ref.current.getBoundingClientRect();
		const { right } = ellipsisRef.current.getBoundingClientRect();
		const posX = right;
		const posY = isMobile ? ref.current.offsetTop + height + 60 : bottom;
		setDropdownPosition({ posX, posY });
	};

	useLayoutEffect(() => {
		onWindowSizeChange();
		window.addEventListener('resize', onWindowSizeChange);

		return () => {
			window.removeEventListener('resize', onWindowSizeChange);
		};
	}, []);

	const onBreadcrumbClick = (index: number) => {
		if (linkTypes?.[index])
			trackNavigationBreadcrumbsClick?.(linkTypes[index]);
	};

	const showMoreLinks = () => {
		setIsDropdownVisible(true);
	};

	const hideMoreLinks = () => {
		if (!isDropdownVisible) return;
		dropDownTimerRef.current = setTimeout(
			() => setIsDropdownVisible(false),
			150,
		);
	};

	const handleDropdownMouseEnter = () => {
		if (dropDownTimerRef?.current) clearTimeout(dropDownTimerRef.current);
	};

	return (
		<nav aria-label='breadcrumbs'>
			<StyledBreadCrumbWrapper
				className='notranslate'
				$isProductPage={isProductPage}
				onMouseLeave={hideMoreLinks}
				ref={ref}
			>
				{linkTexts.slice(0, Math.min(2, size)).map((text, index) => {
					const linkUrl = linkUrls[index];
					const hasUnderline = index === size - 1;

					return (
						<InnerTextOrLink
							index={index}
							onBreadcrumbClick={onBreadcrumbClick}
							text={text}
							size={size}
							linkUrl={linkUrl}
							chevronLimit={size - 1}
							underline={hasUnderline}
						/>
					);
				})}
				<Conditional if={size > 5}>
					<StyledBreadCrumb
						className='link-wrapper'
						onClick={showMoreLinks}
						onMouseEnter={showMoreLinks}
						onMouseLeave={hideMoreLinks}
						onTouchStart={showMoreLinks}
					>
						<StyledBreadCrumbText
							className='breadcrumb-ellipsis'
							title='More crumbs'
							ref={ellipsisRef}
						>
							...
							<Conditional if={isDropdownVisible}>
								<div
									onMouseEnter={handleDropdownMouseEnter}
									onMouseLeave={hideMoreLinks}
								>
									<DropDown
										linkTexts={linkTexts}
										linkUrls={linkUrls}
										linkTitles={linkTitles}
										showMoreLinks={showMoreLinks}
										size={size}
										posX={dropdownPosition?.posX}
										posY={dropdownPosition?.posY}
									/>
								</div>
							</Conditional>
						</StyledBreadCrumbText>
						<ChevronRight
							className={'breadcrumb-icon'}
							aria-hidden='true'
						/>
					</StyledBreadCrumb>
				</Conditional>
				<Conditional if={size > 2}>
					{linkTexts
						.slice(Math.max(2, size - 3), size)
						.map((text, index) => {
							const currentIndex =
								size > 5 ? index + 3 : index + 2;
							const linkUrl = linkUrls[currentIndex];
							const linkTitle = linkTitles
								? linkTitles[currentIndex]
								: '';

							return (
								<InnerTextOrLink
									index={currentIndex}
									onBreadcrumbClick={onBreadcrumbClick}
									linkTitle={linkTitle}
									text={text}
									size={size}
									linkUrl={linkUrl}
									chevronLimit={
										isProductPage
											? size - 1
											: Math.min(size - 1, 5)
									}
								/>
							);
						})}
				</Conditional>
			</StyledBreadCrumbWrapper>
		</nav>
	);
};

export default BreadCrumb;
