import { useCallback } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';
import LSpan from 'Components/common/localizedTags/localizedSpan';

import { StarFullSvg } from 'Assets/svg/star';

import { truncateNumber } from 'Utils/gen';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const ExperienceCategoryWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.75rem;
	margin-top: 0.75rem;

	.product-category {
		display: flex;
		gap: 0.75rem;
		align-items: center;
		text-transform: uppercase;
		color: ${colors.GREY_DS['G3']};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_LARGE_HEAVY)};
	}

	.dot-spacer {
		background: ${colors.GREY_DS['G4a']};
		height: 0.25rem;
		width: 0.25rem;
		border-radius: 0.625rem;
		margin-top: 0.125rem;
	}
`;
const ProductRating = styled.button<{ $hasReviews?: boolean }>`
	display: flex;
	align-items: center;

	.product-rating-value {
		color: ${colors.CANDY_1};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_LARGE_HEAVY)}

		&.rating {
			margin-left: 0.25rem;
		}

		&.new {
			text-transform: uppercase;
			cursor: initial;
		}
	}

	.product-rating-text {
		margin-left: 0.125rem;
		color: ${colors.CANDY_1};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_LARGE)};

		span {
			all: unset;
			${({ $hasReviews }) =>
				$hasReviews &&
				css`
					text-decoration: underline;
				`}
		}
	}

	svg {
		margin-top: 0.125rem;
	}
`;

type Props = {
	primarySubCategory?: Record<string, any>;
	reviewsDetails: {
		reviewsCount: number;
		ratingsCount: number;
		averageRating: number;
		showRatings?: boolean;
	};
	trackWidgetClick?: () => void;
};

const ACCORDION_TITLE_HEIGHT = 140;

const ExperienceCategoryRating = ({
	primarySubCategory,
	reviewsDetails,
	trackWidgetClick,
}: Props) => {
	const { reviewsCount, ratingsCount, averageRating, showRatings } =
		reviewsDetails;

	const onRatingClick = useCallback(() => {
		if (!showRatings) return;
		trackWidgetClick?.();
		const ratingSection = document.querySelector('#reviews-section');
		if (ratingSection) {
			const elementPosition = ratingSection.getBoundingClientRect().top;
			const offsetPosition =
				elementPosition + window.scrollY - ACCORDION_TITLE_HEIGHT;

			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth',
			});
		}
	}, [trackWidgetClick]);

	return (
		<ExperienceCategoryWrapper>
			<Conditional if={!!primarySubCategory?.displayName}>
				<LSpan className='product-category'>
					{primarySubCategory?.displayName}
				</LSpan>

				<div className='dot-spacer' />
			</Conditional>

			<ProductRating
				onClick={onRatingClick}
				aria-label='View product ratings'
				$hasReviews={reviewsCount > 0}
			>
				<Conditional if={showRatings}>
					<StarFullSvg
						width={16}
						height={16}
						color={colors.CANDY_1}
					/>
				</Conditional>

				<span
					className={`product-rating-value ${
						showRatings ? 'rating' : 'new'
					}`}
				>
					{showRatings ? averageRating.toFixed(1) : strings.CMN_NEW}
				</span>

				<Conditional if={showRatings}>
					<div className='product-rating-text'>
						{'('}
						<LSpan>
							{strings.formatString(
								strings.COMBO_UPSELL.RATINGS_TEXT,
								truncateNumber(ratingsCount) ?? 0,
							)}
						</LSpan>
						{')'}
					</div>
				</Conditional>
			</ProductRating>
		</ExperienceCategoryWrapper>
	);
};

export default ExperienceCategoryRating;
