import { SkeletonLoaderType } from 'Components/common/skeletonLoader/inteface';
import { SkeletonLoaderWrapper } from 'Components/common/skeletonLoader/style';

const SkeletonLoader = ({
	width,
	height,
	borderRadius = '0',
	margin,
	className,
}: SkeletonLoaderType) => {
	return (
		<SkeletonLoaderWrapper
			width={width}
			height={height}
			borderRadius={borderRadius}
			margin={margin}
			className={className}
		>
			<div className='shimmer-wrapper'></div>
		</SkeletonLoaderWrapper>
	);
};

export default SkeletonLoader;
