import React, { forwardRef, MouseEvent, ReactElement, useRef } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import { Chevron } from 'Assets/svg/productPage';

import colors from 'Static/typography/colors';

const StyledAccordion = styled.details`
	margin-right: 1.5rem;
	border-top: 1px solid ${colors.GREY.E2};
	width: 100%;
	display: grid;

	.chevron-icon {
		transition: transform 0.1s ease;
	}

	&[open] {
		.chevron-icon {
			transform: rotate(180deg);
		}

		.heading {
			margin-bottom: 1.5rem;
		}
	}

	@media (max-width: 768px) {
		margin-right: 0;
	}
`;

const Title = styled.summary`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: 0.9375rem;
	align-items: center;
	outline: none;
	color: ${colors.GREY[44]};
	margin: 2rem 0 0 0;
	position: relative;
	margin-bottom: 0;
	transition: margin-bottom 0.1s ease;
	list-style: none;

	&::-webkit-details-marker {
		display: none;
	}

	&:focus-visible {
		outline: 0.125rem solid ${colors.BRAND_COLORS.PURPS};
		outline-offset: 0.125rem;
		border-radius: 0.25rem;
	}

	.heading-text:after {
		height: 1.5rem;
		display: block;
		content: '';
		position: absolute;
		top: 100%;
		width: 100%;
	}

	.heading-text {
		cursor: pointer;
	}

	@media (max-width: 768px) {
		.heading-text:after {
			display: none;
		}
	}
`;

const ContentBlock = styled.div`
	p {
		margin: 0;
	}

	a {
		color: ${colors.PURPLE};
	}

	img {
		width: 100%;
	}
`;
type AccordionProps = {
	clickHandler?: (e: MouseEvent) => void;
	isAccordionPanelOpen?: boolean;
	heading?: ReactElement | null;
	content: any;
	useSchema?: boolean;
	renderAsChildren?: boolean;
	trackClickEvent?: ({
		accordionState,
		target,
	}: {
		accordionState: boolean;
		target: EventTarget;
	}) => void;
};
const Accordion = (
	{
		heading,
		content,
		isAccordionPanelOpen = false,
		clickHandler,
		useSchema = false,
		renderAsChildren = false,
		trackClickEvent,
	}: AccordionProps,
	ref: any,
) => {
	const accordionRef = useRef<HTMLDetailsElement>(null);

	const handleClick = (e: MouseEvent) => {
		// Run custom click handler passed through props.
		clickHandler?.(e);

		setTimeout(() => {
			if (!accordionRef.current) return;

			const isOpen = accordionRef.current.hasAttribute('open');

			if (trackClickEvent) {
				trackClickEvent({ accordionState: isOpen, target: e.target });
			}

			// Get the summary element.
			const summary = accordionRef.current.querySelector('summary');

			if (summary) {
				summary.setAttribute(
					'aria-expanded',
					isOpen ? 'true' : 'false',
				);
			}
		}, 0);
	};

	return (
		<StyledAccordion
			ref={accordionRef}
			open={isAccordionPanelOpen}
			{...(useSchema && {
				itemProp: 'mainEntity',
				itemType: 'https://schema.org/Question',
				itemScope: true,
			})}
		>
			<Title
				role='button'
				tabIndex={0}
				className='heading'
				onClick={handleClick}
				aria-expanded={isAccordionPanelOpen ? 'true' : 'false'}
			>
				<div
					ref={ref}
					className='heading-text core-section-header-container'
					{...(useSchema && {
						itemProp: 'name',
					})}
				>
					{heading}
				</div>
				<div>
					<Chevron className={'chevron-icon'} />
				</div>
			</Title>
			<ContentBlock
				className={'content-body'}
				{...(useSchema && {
					itemProp: 'acceptedAnswer',
					itemType: 'https://schema.org/Answer',
					itemScope: true,
				})}
			>
				{renderAsChildren ? (
					content
				) : (
					<span
						{...(useSchema && {
							itemProp: 'text',
						})}
					>
						{content}
					</span>
				)}
			</ContentBlock>
		</StyledAccordion>
	);
};

export default forwardRef(Accordion);
